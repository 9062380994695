import { combineReducers } from 'redux'
import theme from './slices/themeSlice'
import chat from './slices/chatSlice'
import auth from './slices/authSlice'
import mail from './slices/mailSlice'
import app from './slices/appSlice'
import { createAction } from '@reduxjs/toolkit'

export const revertAll = createAction('REVERT_ALL')

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        app,
        theme,
        auth,
        chat,
        mail,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
