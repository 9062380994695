import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig'


export const APP_NAME = 'Ip.Call Go lite';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const LOGO_PATH = env.LOGO_PATH
export const PBX_RECORDFILE = 'http://192.168.100.57'
export const URL_RECORDFILE = 'http://192.168.100.41'
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';
export const MAIL_BASE_URL =env.MAIL_BASE_URL
export const HOST_URL =env.HOST_URL
export const HOST =env.HOST
export const STATIC_FOLDER = env.STATIC_ENDPOINT_URL
export const MAIL_BASE_PROXY_URL =env.MAIL_BASE_PROXY_URL
export const CHAT_API_URL = env.CHAT_API_URL
export const CHAT_BASE_URL = env.CHAT_BASE_URL
export const MAIL_SOCKET = env.MAIL_SOCKET
export const CHAT_SOCKET = env.CHAT_SOCKET
export const CONSOLA_API_URL = env.CONSOLA_API_URL
export const FACEBOOK_APP_ID = env.FACEBOOK_APP_ID
export const GOOGLE_API_CLIENT_ID = env.GOOGLE_API_CLIENT_ID
export const NOTIFICACIONES_SOCKET = env.NOTIFICACIONES_SOCKET;
export const CONSOLA_SOCKET = env.CONSOLA_SOCKET;
export const REDIRECT_URL_KEY = 'redirect'
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/home`;
export const UNAUTHENTICATED_ENTRY = '/login'

const ipcall_config = {
	invertirColores: (localStorage.getItem('invertirColores') === 'true') || false,
	rightNavCollapsed: true,
	mobileRightNav: false,
}

export const THEME_CONFIG = {
	...ipcall_config,
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: localStorage.getItem('locale') || 'es',
	zonatime:localStorage.getItem('tz') || 'America/Argentina/Buenos_Aires',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '#2A3542',
	mobileNav: false,
	currentTheme: localStorage.getItem('theme') || 'light',
	direction: DIR_LTR,
	blankLayout: false
};
