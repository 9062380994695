import { 
    THEME_COLOR, 
    DARK_MODE,
    GRAY_SCALE,
    BORDER,
    BODY_BACKGROUND
} from 'constants/ThemeConstant'

export function rgba(hex, opacity = 1) {
    if (!/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      throw new Error('Invalid Hex');
    }
  
    const decimal = parseInt(hex.substring(1), 16);
  
    const r = (decimal >> 16) & 255;
    const g = (decimal >> 8) & 255;
    const b = decimal & 255;
  
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

const ipcallTheme = {
    "colorPrimary": "#9b1d5d",
    "colorPrimaryBgHover": "#FF69B6",
    "colorPrimaryBg": "#F3F3F3",
    "colorPrimaryText": "#9E9E9E",
    "colorPrimaryTextHover": "#F3F3F3",
    "colorPrimaryTextActive": "#F3F3F3"
}

export const baseTheme = {
    borderRadius: 6,
    colorPrimary: '#146084',
    colorSuccess: THEME_COLOR.CYAN,
    colorWarning: THEME_COLOR.GOLD,
    colorError: THEME_COLOR.VOLCANO,
    colorInfo: THEME_COLOR.BLUE,
    colorText: '#080F44',
    colorBorder: BORDER.BASE_COLOR,
    bodyBg: BODY_BACKGROUND,
    controlHeight: 40,
    controlHeightLG: 48,
    controlHeightSM: 36,
    fontFamily: `'Roboto', 'Poppins', 'Rubik'`,
    fontSizeHeading2: 22,
    fontSizeHeading4: 17,
    ...ipcallTheme
}

const getBaseComponentThemeConfig = (
    {
        Button = {
            colorBgContainer: '#FFFFFF',
            colorText: "#636363",
            colorBorder: "#d9d9d9",
        },
        Checkbox = {
            colorBgContainer: '#FFFFFF',
            colorBorder: '#d9d9d9',
        },
        Menu = {fontWeight: "100 !import"}, 
        Card = {},
        Table = {},
        Select = {
            colorBgContainer: '#FFFFFF',
            colorTextPlaceholder: "#818181",
            colorText: "#636363",
            colorBorder: '#d9d9d9',
        },
        DatePicker = {
            colorBgContainer: '#FFFFFF',
            colorTextPlaceholder: "#818181",
            colorText: "#636363",
            colorBorder: "#d9d9d9",
        },
        Layout = {},
        Input = {
            colorBgContainer: '#FFFFFF',
            colorText: "#636363",
            colorBorder: "#d9d9d9",
        },
        InputNumber = {
            colorBgContainer: '#FFFFFF',
            colorText: "#636363",
            colorBorder: "#d9d9d9",
        },
        Dropdown = {},
        Calendar = {},
        Radio={
            colorBgContainer: '#F3F3F3',
            colorTextPlaceholder: "#818181",
            colorText: "#636363",
            colorBorder: "#d9d9d9",
        },
    } = {}
) => {
    return {
        Button: {
            borderRadius: '6px',
            borderRadiusLG: '6px',
            borderRadiusSM: '6px',
            colorPrimary: "#146084",
            colorPrimaryBg: '#b9c3c4',
            colorPrimaryBgHover: '#91b1b8',
            colorPrimaryBorder: '#6c9dab',
            colorPrimaryBorderHover: '#4a899e',
            colorPrimaryHover: '#2d7591',
            colorPrimaryActive: '#09415e',
            colorPrimaryTextHover: '#2d7591',
            colorPrimaryText: '#146084',
            colorPrimaryTextActive: '#09415e',
            ...Button,
        },
        Divider: {
            margin: 15,
            marginLG: 15,
            marginSM: 15,
        },
        Dropdown: {
            controlPaddingHorizontal: 20,
            controlHeight: 37,
            borderRadiusLG: 10,
            paddingXXS: '4px 0px',
            ...Dropdown
        },
        Calendar: {
            ...Calendar
        },
        Checkbox: {
            lineWidth: 2,
            borderRadiusSM: 4,
            ...Checkbox,
        },
        Card: {
            colorTextHeading: GRAY_SCALE.GRAY_DARK,
            paddingLG: 20,
            ...Card
        },
        Layout: {
            bodyBg: BODY_BACKGROUND,
            headerBg: GRAY_SCALE.WHITE,
            ...Layout
        },
        Breadcrumb: {
            colorTextDescription: GRAY_SCALE.GRAY_LIGHT,
            colorText: baseTheme.colorPrimary,
            colorBgTextHover: 'transparent'
        },
        Menu: {
            itemBg: 'transparent',
            colorActiveBarHeight: 0,
            colorActiveBarWidth: 3,
            horizontalItemSelectedColor: baseTheme.colorPrimary,
            itemHoverColor: baseTheme.colorPrimary,
            itemSelectedColor: baseTheme.colorPrimary,
            itemSelectedBg: rgba(baseTheme.colorPrimary, 0.1),
            itemHoverBg: 'transparent',
            itemBorderRadius: 0,
            subMenuItemBg: 'transparent',
            itemMarginInline: 0,
            controlHeightLG: 40,
            controlHeightSM: 22,
            ...Menu
        },
        Input: {
            borderRadius: '6px',
            borderRadiusLG: '6px',
            borderRadiusSM: '6px',
            ...Input
        },
        InputNumber: {
            borderRadius: '6px',
            borderRadiusLG: '6px',
            borderRadiusSM: '6px',
            ...InputNumber,
        },
        Pagination: {
            paginationItemSize: 30,
            borderRadius: '6px',
            borderRadiusLG: '6px',
            borderRadiusSM: '6px',
            colorBgContainer: baseTheme.colorPrimary,
            colorPrimary:  GRAY_SCALE.WHITE,
            colorPrimaryHover: GRAY_SCALE.WHITE,
        },
        Steps: {
            wireframe: true,
            controlHeight: 32,
            waitIconColor: GRAY_SCALE.GRAY_LIGHT
        },
        DatePicker: {
            controlHeight: 40,
            controlHeightSM: 26,
            borderRadiusSM: 6,
            lineWidthBold: 0,
            borderRadius: '6px',
            ...DatePicker
        },
        Radio: {
            fontSizeLG: 18,
            borderRadius: '6px',
            borderRadiusLG: '6px',
            borderRadiusSM: '6px',
            ...Radio,
        },
        Slider: {
            colorPrimaryBorder: rgba(baseTheme.colorPrimary, 0.8),
            colorPrimaryBorderHover: baseTheme.colorPrimary
        },
        Select: {
            paddingXXS: '4px 0px',
            borderRadiusSM: '6px',
            borderRadiusXS: '6px',
            controlItemBgActive: rgba(baseTheme.colorPrimary, 0.1),
            ...Select
        },
        TreeSelect: {
            controlHeightSM: 24
        },
        Tooltip: {
            colorBgDefault: rgba(GRAY_SCALE.DARK, 0.75),
            controlHeight: 32
        },
        Timeline: {
            lineType: 'dashed'
        },
        Tag: {
            lineHeight: 2.1
        },
        Table: {
            colorText: '#080F44',
            tableHeaderBg: 'transparent',
            paddingContentVerticalLG: 5,
            ...Table
        },
    }
}

export const lightTheme = {
    token: {
        ...baseTheme,
        colorTextBase: GRAY_SCALE.GRAY,
    },
    components: getBaseComponentThemeConfig()
}



export const darkTheme = {
    token: {
        ...baseTheme,
        colorTextBase: DARK_MODE.TEXT_COLOR,
        colorBgBase: DARK_MODE.BG_COLOR,
        colorBorder: DARK_MODE.BORDER_BASE_COLOR,
        colorText: DARK_MODE.TEXT_COLOR,
    },
    components: getBaseComponentThemeConfig({
        Button: {
            controlOutline: 'transparent',
            colorBorder: DARK_MODE.BORDER_BASE_COLOR,
            colorText: DARK_MODE.TEXT_COLOR,
        },
        Checkbox: {
            //Aunque vacio tiene que estar para remplazar estilos claros
        },
        Card: {
            colorTextHeading: DARK_MODE.HEADING_COLOR,
        },
        Layout: {
            bodyBg: '#1b2531',
            colorBgHeader: DARK_MODE.BG_COLOR
        },
        Menu: {
            itemSelectedBg: 'transparent',
            colorItemTextSelectedHorizontal: GRAY_SCALE.WHITE,
            itemHoverColor: GRAY_SCALE.WHITE,
            colorItemTextSelected: GRAY_SCALE.WHITE,
            colorItemText: DARK_MODE.TEXT_COLOR,
            colorActiveBarWidth: 0,
            boxShadowSecondary: DARK_MODE.DROP_DOWN_SHADOW
        },
        Dropdown: {
            boxShadowSecondary: DARK_MODE.DROP_DOWN_SHADOW
        },
        Calendar: {
            controlItemBgActive: '#303a4e'
        },
        Select: {
            controlOutline: 'transparent',
            controlItemBgActive: DARK_MODE.ACTIVE_BG_COLOR,
            controlItemBgHover: DARK_MODE.HOVER_BG_COLOR,
            boxShadowSecondary: DARK_MODE.DROP_DOWN_SHADOW
        },
        Input: {
            controlOutline: 'transparent',
        },
        DatePicker: {
            controlOutline: 'transparent',
            boxShadowSecondary: DARK_MODE.DROP_DOWN_SHADOW,
            controlItemBgActive: DARK_MODE.ACTIVE_BG_COLOR,
            controlItemBgHover: DARK_MODE.HOVER_BG_COLOR,
        },
        Table: {
            colorText: DARK_MODE.TEXT_COLOR,
            colorTextHeading: DARK_MODE.HEADING_COLOR
        },
        Radio: {

        },
        InputNumber: {

        },
    })
}