import { createSlice } from '@reduxjs/toolkit'
import { revertAll } from 'store/rootReducer'

const initialState = {}

export const chat = createSlice({
	name: 'chat',
	initialState,
	extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
	reducers: {
        setChatCanales: (state, action) => {
			state.canales = action.payload
		},
		setChatGrupos: (state, action) => {
			state.grupos = action.payload
		},
		setChatConversaciones: (state, action) => {
			state.conversaciones = action.payload
		},
		setChatInternoNoLeidos: (state, action) => {
			state.internoNoLeidos = action.payload
		},
		setChatInternoNoLeidosGrupos: (state, action) => {
			state.internoNoLeidosGrupos = action.payload
		},
	},
})

export const { 
	setChatCanales,
	setChatGrupos,
	setChatConversaciones,
	setChatInternoNoLeidos,
	setChatInternoNoLeidosGrupos,
} = chat.actions

export default chat.reducer