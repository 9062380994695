import { createSlice } from '@reduxjs/toolkit'
import { THEME_CONFIG } from 'configs/AppConfig'
import { revertAll } from 'store/rootReducer'

export const initialState = THEME_CONFIG

export const themeSlice = createSlice({
	name: 'theme',
	initialState,
	extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
	reducers: {
        toggleCollapsedNav: (state, action) => {
			state.navCollapsed = action.payload
			if(!action.payload) state.rightNavCollapsed = true
		},
		toggleCollapsedRightNav: (state, action) => {
			state.rightNavCollapsed = action.payload
			if(!action.payload) state.navCollapsed = true
		},
        onNavStyleChange: (state, action) => {
			state.sideNavTheme = action.payload
		},
        onLocaleChange: (state, action) => {
			state.locale = action.payload
		},
        onZonaChange: (state, action) => {
			state.zonatime = action.payload
		},
        onNavTypeChange: (state, action) => {
			state.navType = action.payload
		},
        onTopNavColorChange: (state, action) => {
			state.topNavColor = action.payload
		},
        onHeaderNavColorChange: (state, action) => {
			state.headerNavColor = action.payload
		},
        onMobileNavToggle: (state, action) => {
			state.mobileNav = action.payload
		},
		onMobileRightNavToggle: (state, action) => {
			state.mobileRightNav = action.payload
		},
        onSwitchTheme: (state, action) => {
			state.currentTheme = action.payload
		},
        onDirectionChange: (state, action) => {
			state.direction = action.payload
		},
		onBlankLayout: (state, action) => {
			state.blankLayout = action.payload
		},
		onInvertirColores: (state, action) => {
			state.invertirColores = action.payload
		},
	},
})

export const { 
	toggleCollapsedRightNav,
    toggleCollapsedNav,
    onNavStyleChange,
    onLocaleChange,
	onZonaChange,
    onNavTypeChange,
    onTopNavColorChange,
    onHeaderNavColorChange,
    onMobileNavToggle,
	onMobileRightNavToggle,
    onSwitchTheme,
    onDirectionChange,
	onBlankLayout,
	onInvertirColores,
} = themeSlice.actions

export default themeSlice.reducer