import { createSlice } from '@reduxjs/toolkit'
import { revertAll } from 'store/rootReducer'

const initialState = {
	mails: [],
	mailIcon:{
		noVistos: [],
		isConnected: false,
	}
}

export const mail = createSlice({
	name: 'mail',
	initialState,
	extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
	reducers: {
		setInitialMails: (state, action) => {
			state.mails = action.payload
		},
		setMailIcon: (state, action) => {
			state.mailIcon = { ...state.mailIcon , ...action.payload}
		},
		setMailConnected: (state, action) => {
			state.isConnected = action.payload
		},
		setMailNoVistosCount: (state, action) => {
			state.noVistos = action.payload
		},
		setNewNoVisto: (state, action) => {
			state.mail_id = action.payload
		},
	},
})

export const { 
	setInitialMails,
	setMailIcon,
	setMailConnected,
	setMailNoVistosCount,
	setNewNoVisto,
} = mail.actions

export default mail.reducer