import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
    {
        key: 'terms',
        path: `/terminos-y-condiciones`,
        component: React.lazy(() => import('views/auth-views/terms')),
    },
    {
        key: 'privacidad',
        path: `/politicas-privacidad`,
        component: React.lazy(() => import('views/auth-views/privacy')),
    },
]

export const protectedRoutes = [
    {
        key: `home`,
        path: `${APP_PREFIX_PATH}/home`,
        component: React.lazy(() => import(`views/app-views/dashboard`)),
    },
    {
        key: `cuenta`,
        path: `${APP_PREFIX_PATH}/cuenta`,
        component: React.lazy(() => import(`views/app-views/cuentas`)),
    },
    {
        key: `cuenta/id`,
        path: `${APP_PREFIX_PATH}/cuenta/:id`,
        component: React.lazy(() => import(`views/app-views/cuentas`)),
    },
    {
        key: `contactos`,
        path: `${APP_PREFIX_PATH}/contactos`,
        component: React.lazy(() => import(`views/app-views/contactos`)),
    },
    {
        key: `calendar`,
        path: `${APP_PREFIX_PATH}/calendar`,
        component: React.lazy(() => import(`views/app-views/calendar`)),
    },
    {
        key: `roles`,
        path: `${APP_PREFIX_PATH}/roles`,
        component: React.lazy(() => import(`views/app-views/roles`)),
    },
    {
        key: `clientes`,
        path: `${APP_PREFIX_PATH}/clientes`,
        component: React.lazy(() => import(`views/app-views/clientes`)),
    },
    {
        key: `clientes/editar`,
        path: `${APP_PREFIX_PATH}/clientes/editar`,
        component: React.lazy(() => import(`views/app-views/clientes/Editar`)),
    },
    {
        key: `usuarios`,
        path: `${APP_PREFIX_PATH}/usuarios`,
        component: React.lazy(() => import(`views/app-views/usuarios`)),
    },
    {
        key: `campaigns`,
        path: `${APP_PREFIX_PATH}/campaigns`,
        component: React.lazy(() => import(`views/app-views/campanias`)),
    },
    {
        key: `campaigns/dashboard`,
        path: `${APP_PREFIX_PATH}/campaigns/dashboard`,
        component: React.lazy(() => import(`views/app-views/campanias/components/dashboard`)),
    },
    {
        key: `campaigns/strategies`,
        path: `${APP_PREFIX_PATH}/campaigns/strategies`,
        component: React.lazy(() => import(`views/app-views/campanias/components/estrategias`)),
    },
    {
        key: `campaigns/filters`,
        path: `${APP_PREFIX_PATH}/campaigns/filters`,
        component: React.lazy(() => import(`views/app-views/campanias/components/filtros`)),
    },
    {
        key: `canales`,
        path: `${APP_PREFIX_PATH}/canales/:canal`,
        component: React.lazy(() => import(`views/app-views/canales`)),
    },
    {
        key: `canales/ml/publicaciones`,
        path: `${APP_PREFIX_PATH}/canales/ml/publicaciones`,
        component: React.lazy(() => import(`views/app-views/canales/verPublicacionesML`)),
    },
    {
        key: `canales/instagram/publicaciones`,
        path: `${APP_PREFIX_PATH}/canales/instagram/publicaciones`,
        component: React.lazy(() => import(`views/app-views/canales/verPublicacionesInstagram`)),
    },
    {
        key: `canales/WhatsAppBusiness/configurar`,
        path: `${APP_PREFIX_PATH}/canales/WhatsAppBusiness/configurar`,
        component: React.lazy(() => import(`views/app-views/canales/configurarWspBusiness`)),
    },
    {
        key: `canales/facebook/publicaciones`,
        path: `${APP_PREFIX_PATH}/canales/facebook/publicaciones`,
        component: React.lazy(() => import(`views/app-views/canales/verPublicacionesFacebook`)),
    },
    {
        key: `canales/ml/publicaciones/comentarios`,
        path: `${APP_PREFIX_PATH}/canales/ml/publicaciones/comentarios`,
        component: React.lazy(() => import(`views/app-views/canales/verPublicacionesML/verComentarios`)),
    },
    {
        key: `canales/ml/publicaciones/ventas`,
        path: `${APP_PREFIX_PATH}/canales/ml/publicaciones/ventas`,
        component: React.lazy(() => import(`views/app-views/canales/verPublicacionesML/verVentas`)),
    },
    {
        key: `supervisores`,
        path: `${APP_PREFIX_PATH}/supervisores`,
        component: React.lazy(() => import(`views/app-views/supervisores`)),
    },
    {
        key: `bots`,
        path: `${APP_PREFIX_PATH}/bots`,
        component: React.lazy(() => import(`views/app-views/bots`)),
    },
    {
        key: `bots/configurar`,
        path: `${APP_PREFIX_PATH}/bots/configurar`,
        component: React.lazy(() => import(`views/app-views/bots/configurarBot`)),
    },
    {
        key: `bots/horarios`,
        path: `${APP_PREFIX_PATH}/bots/horarios`,
        component: React.lazy(() => import(`views/app-views/bots/horarios`)),
    },
    {
        key: `Llamados Dashboard`,
        path: `${APP_PREFIX_PATH}/llamados`,
        component: React.lazy(() => import(`views/app-views/llamados_dashboard`)),
    },
    {
        key: `Chat Dashboard`,
        path: `${APP_PREFIX_PATH}/dashboard/chat`,
        component: React.lazy(() => import(`views/app-views/chat_dashboard`)),
    },
    {
        key: `lookups`,
        path: `${APP_PREFIX_PATH}/lookups`,
        component: React.lazy(() => import(`views/app-views/lookups`)),
    },
    {
        key: `formularios/administrar`,
        path: `${APP_PREFIX_PATH}/formularios/administrar`,
        component: React.lazy(() => import(`views/app-views/formularios/generarFormularios`)),
    },
    {
        key: `formularios/administrar/editar`,
        path: `${APP_PREFIX_PATH}/formularios/administrar/editar`,
        component: React.lazy(() => import(`views/app-views/formularios/generarFormularios/EditarNuevo`)),
    },
    {
        key: `formularios/ver`,
        path: `${APP_PREFIX_PATH}/formularios/ver`,
        component: React.lazy(() => import(`views/app-views/formularios/verFormularios`)),
    },
    {
        key: `cuenta/completarFormulario/:idcuentaencuesta`,
        path: `${APP_PREFIX_PATH}/cuenta/completarFormulario/:idcuentaencuesta`,
        component: React.lazy(() => import(`views/app-views/cuentas/completarFormulario`)),
    },
    {
        key: `convenios`,
        path: `${APP_PREFIX_PATH}/convenios`,
        component: React.lazy(() => import(`views/app-views/convenios`)),
    },
    {
        key: `accesos`,
        path: `${APP_PREFIX_PATH}/accesos`,
        component: React.lazy(() => import(`views/app-views/accesos`)),
    },
    {
        key: `editarPerfil`,
        path: `${APP_PREFIX_PATH}/editarPerfil`,
        component: React.lazy(() => import(`views/app-views/editarPerfil`)),
    },
    {
        key: `search`,
        path: `${APP_PREFIX_PATH}/search`,
        component: React.lazy(() => import(`views/app-views/search`)),
    },
    {
        key: `acuerdos`,
        path: `${APP_PREFIX_PATH}/acuerdos`,
        component: React.lazy(() => import(`views/app-views/acuerdos`)),
    },
    {
        key: `acuerdos/ver`,
        path: `${APP_PREFIX_PATH}/acuerdos/ver/:id`,
        component: React.lazy(() => import(`views/app-views/acuerdos/view.js`)),
    },
    
    {
        key: `monitor`,
        path: `${APP_PREFIX_PATH}/monitor/telefonia`,
        component: React.lazy(() => import(`views/app-views/monitorTelefonico`)),
    },
    {
        key: `monitor`,
        path: `${APP_PREFIX_PATH}/monitor/chat`,
        component: React.lazy(() => import(`views/app-views/monitorChat`)),
    },
    {
        key: `historial`,
        path: `${APP_PREFIX_PATH}/historial`,
        component: React.lazy(() => import(`views/app-views/historial`)),
    },
    {
        key: `grupos`,
        path: `${APP_PREFIX_PATH}/grupos`,
        component: React.lazy(() => import(`views/app-views/grupos`)),
    },
    {
        key: `grupos/estadisticas`,
        path: `${APP_PREFIX_PATH}/grupos/estadisticas`,
        component: React.lazy(() => import(`views/app-views/grupos/estadisticasGrupos.js`)),
    },
    {
        key: `reportes/administrar`,
        path: `${APP_PREFIX_PATH}/reportes/administrar`,
        component: React.lazy(() => import(`views/app-views/reportes/administrarReportes`)),
    },
    {
        key: `reportes/editar`,
        path: `${APP_PREFIX_PATH}/reportes/editar`,
        component: React.lazy(() => import(`views/app-views/reportes/administrarReportes/EditarReportes`)),
    },
    {
        key: `reportes/ver`,
        path: `${APP_PREFIX_PATH}/reportes/ver`,
        component: React.lazy(() => import(`views/app-views/reportes/verReportes`)),
    },
    {
        key: `reportes/ver/preview`,
        path: `${APP_PREFIX_PATH}/reportes/ver/preview`,
        component: React.lazy(() => import(`views/app-views/reportes/verReportes/preview`)),
    },
    {
        key: `reportes/generarWizard`,
        path: `${APP_PREFIX_PATH}/reportes/generarWizard`,
        component: React.lazy(() => import(`views/app-views/reportes/generarReportesWizard`)),
    },
    {
        key: `agentes`,
        path: `${APP_PREFIX_PATH}/agentes`,
        component: React.lazy(() => import(`views/app-views/agentes`)),
    },
    {
        key: `flujosDeEstados`,
        path: `${APP_PREFIX_PATH}/flujosDeEstados`,
        component: React.lazy(() => import(`views/app-views/flujosDeEstados`)),
    },
    {
        key: `flujosDeEstados/configurar`,
        path: `${APP_PREFIX_PATH}/flujosDeEstados/configurar`,
        component: React.lazy(() => import(`views/app-views/flujosDeEstados/configurarArbol`)),
    },
    {
        key: `actividadesTipos`,
        path: `${APP_PREFIX_PATH}/actividadesTipos`,
        component: React.lazy(() => import(`views/app-views/actividadesTipos`)),
    },
    {
        key: `actividadesTipos/codigos/`,
        path: `${APP_PREFIX_PATH}/actividadesTipos/codigos/`,
        component: React.lazy(() => import(`views/app-views/actividadesTipos/actividadesCodigos/actividadesCodigos`)),
    },
    {
        key: `actividadesTipos/codigos/resultados`,
        path: `${APP_PREFIX_PATH}/actividadesTipos/codigos/resultados`,
        component: React.lazy(() => import(`views/app-views/actividadesTipos/actividadesCodigos/actividadesResultados/actividadesResultados`)),
    },
    {
        key: `actividadesTipos/codigos/resultados/motivos`,
        path: `${APP_PREFIX_PATH}/actividadesTipos/codigos/resultados/motivos`,
        component: React.lazy(() => import(`views/app-views/actividadesTipos/actividadesCodigos/actividadesResultados/actividadesMotivos/actividadesMotivos`)),
    },
    {
        key: `interfaces`,
        path: `${APP_PREFIX_PATH}/interfaces`,
        component: React.lazy(() => import(`views/app-views/interfaces`)),
    },
    {
        key: `interfaces/:id/historial`,
        path: `${APP_PREFIX_PATH}/interfaces/:id/historial`,
        component: React.lazy(() => import(`views/app-views/interfaces/historial.js`)),
    },    
    {
        key: `interfaces/:id/editar`,
        path: `${APP_PREFIX_PATH}/interfaces/:id/design`,
        component: React.lazy(() => import(`views/app-views/interfaces/components/interfazDesign.js`)),
    },
    {
        key: `interfaces/nuevoeditar`,
        path: `${APP_PREFIX_PATH}/interfaces/nuevoeditar`,
        component: React.lazy(() => import(`views/app-views/interfaces/NuevoEditar/index.js`)),
    },
    {
        key: `interfaces/cargadatos`,
        path: `${APP_PREFIX_PATH}/interfaces/cargadatos`,
        component: React.lazy(() => import(`views/app-views/interfaces/cargaDatos/index.js`)),
    },
    {
        key: `templates`,
        path: `${APP_PREFIX_PATH}/templates`,
        component: React.lazy(() => import(`views/app-views/templates`)),
    },
    {
        key: `mail`,
        path: `${APP_PREFIX_PATH}/mail/*`,
        component: React.lazy(() => import(`views/app-views/mail`)),
    },
    {
        key: 'setting',
        path: `${APP_PREFIX_PATH}/setting/*`,
        component: React.lazy(() => import('views/app-views/setting')),
    },
    {
        key: `productos`,
        path: `${APP_PREFIX_PATH}/productos`,
        component: React.lazy(() => import(`views/app-views/productos`)),
    },
    {
        key: `productosimportar`,
        path: `${APP_PREFIX_PATH}/productos/importar`,
        component: React.lazy(() => import(`views/app-views/productos/importar`)),
    },
    {
        key: `productosedit`,
        path: `${APP_PREFIX_PATH}/productos/:id`,
        component: React.lazy(() => import(`views/app-views/productos/Editar`)),
    },
    {
        key: `notificaciones`,
        path: `${APP_PREFIX_PATH}/notificaciones`,
        component: React.lazy(() => import(`views/app-views/notificaciones`)),
    },
    {
        key: `dashboard`,
        path: `${APP_PREFIX_PATH}/dashboard`,
        component: React.lazy(() => import(`views/app-views/dashboard`)),
    },
    {
        key: `actividadesDeUsuarios`,
        path: `${APP_PREFIX_PATH}/actividadesDeUsuarios`,
        component: React.lazy(() => import(`views/app-views/actividadesDeUsuarios`)),
    },
    {
        key: `leads`,
        path: `${APP_PREFIX_PATH}/leads`,
        component: React.lazy(() => import(`views/app-views/leads`)),
    },
    {
        key: `audios`,
        path: `${APP_PREFIX_PATH}/audios`,
        component: React.lazy(() => import(`views/app-views/audios`)),
    },
    {
        key: `eventos`,
        path: `${APP_PREFIX_PATH}/eventos`,
        component: React.lazy(() => import(`views/app-views/eventos`)),
    },
    {
        key: `monedas`,
        path: `${APP_PREFIX_PATH}/monedas`,
        component: React.lazy(() => import(`views/app-views/monedas`)),
    },
    {
        key: `personalizacionGlobal`,
        path: `${APP_PREFIX_PATH}/personalizacionGlobal`,
        component: React.lazy(() => import(`views/app-views/configuracionSitio`)),
    }

]