import { createSlice } from '@reduxjs/toolkit'
import { revertAll } from 'store/rootReducer'

const initialState = {
	preferenciasGlobales: JSON.parse(localStorage.getItem('preferenciasGlobales')) || [],
	updateNotificaciones: false,
	globalUpdate: {},
}

export const app = createSlice({
	name: 'chat',
	initialState,
	extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
	reducers: {
        setPreferenciasGlobales: (state, action) => {
			state.preferenciasGlobales = action.payload
		},
		updateNotificaciones: (state) => {
			state.updateNotificaciones = !state.updateNotificaciones
		},
		setGlobalUpdate: (state, action) => {
			state.globalUpdate = {...state.globalUpdate, ...action.payload}
		},
		updateConversation: (state, action) => {
			state.updateConversation = !state.updateConversation
		},
	},
})

export const { 
	setPreferenciasGlobales,
	updateNotificaciones,
	setGlobalUpdate,
	updateConversation,
} = app.actions

export default app.reducer